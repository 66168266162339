import React, { useEffect } from "react";
import { FC, useRef, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { useWallet } from "../../core/WalletContextProvider";
import { CrypromusCrncInfo } from "../../core/_models";
import { useGetExchange } from "../../core/_requests";

interface Props {
  selectedCurrency: CrypromusCrncInfo;
  setSelectedCurrency: React.Dispatch<React.SetStateAction<CrypromusCrncInfo>>;
}

export const CurrencyPageCrypto: FC<Props> = ({
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const intl = useIntl();

  const { currencies } = useWallet();

  const handleCurrencyClick = (currency: CrypromusCrncInfo) => {
    setSelectedCurrency(currency);
  };

  const availableCurrencies = currencies?.filter(
    (currency) => currency.is_available
  );

  useEffect(() => {
    if (selectedCurrency?.currency) getExchange(selectedCurrency?.currency);
  }, [selectedCurrency?.currency]);

  const {
    mutate: getExchange,
    data: dataGetExchange,
    isLoading: isGetExchangeLoading,
  } = useGetExchange();

  return (
    <div>
      <div
        className="stepper stepper-links d-flex flex-column gap-5"
        id="kt_modal_top_up_wallet_stepper"
      >
        <div className="stepper-nav justify-content-center">
          <div
            className="stepper-item current my-0 mb-3"
            data-kt-stepper-element="nav"
          >
            <h3 className="stepper-title">
              {intl.formatMessage({ id: "WALLET.STEP1.TITLE" })}
            </h3>
          </div>
        </div>
      </div>
      <div className="mt-3 row row-cols-4 row-cols-md-4 g-5 h-350px scroll-y">
        {availableCurrencies?.map((currencyInfo, index) => (
          <div
            className="col"
            key={currencyInfo.currency + currencyInfo.network}
          >
            <input
              type="radio"
              className="btn-check"
              name="select_coin"
              id={`kt_modal_top_up_wallet_coin_option_${index}`}
              checked={
                selectedCurrency?.currency === currencyInfo.currency &&
                selectedCurrency?.network === currencyInfo.network
              }
              onChange={() => handleCurrencyClick(currencyInfo)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-active-light-primary p-2 p-md-7  d-flex flex-column flex-center"
              htmlFor={`kt_modal_top_up_wallet_coin_option_${index}`}
            >
              <SVG
                className="w-50px h-50px"
                src={toAbsoluteUrl(
                  `/media/wallets/${currencyInfo.currency}.svg`
                )}
              />
              <div className="fs-5 fw-bold">{currencyInfo.currency}</div>
              <div className="fs-8 fw-bold">{currencyInfo.network}</div>
            </label>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <p className="fs-7 text-muted">
          {intl.formatMessage({ id: "WALLET.TOP_UP_INFO" })}
        </p>
        <p className="fs-6 fw-bold mb-0">
          {intl.formatMessage({ id: "WALLET.APPROX_RATE" })}{" "}
          {isGetExchangeLoading && (
            <span className="spinner-border spinner-border-sm align-middle mx-2 mb-1"></span>
          )}
          <span className="ms-2">{dataGetExchange || ""}</span>
        </p>
      </div>
    </div>
  );
};
