/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { MainPage } from "../pages/main-page/MainPage";
import { Faq } from "../modules/faq/Faq";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          {/* <Route path="logout" element={<Logout />} /> */}
          <Route element={<MasterLayout />}>
            <Route path="faq/" element={<Faq />} />
          </Route>

          {currentUser ? (
            <>
              <Route path="/" element={<Navigate to="/deals/create" />} />
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              <Route element={<MasterLayout />}>
                <Route path="/" element={<MainPage />} />
              </Route>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
