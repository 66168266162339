import axios from "axios";
import { RecoveryPinSheena, RecoveryTGSheena, UserModel } from "..";
import { useMutation } from "react-query";

export const GET_USER_BY_ACCESSTOKEN_URL = `/api/profile/`;

export const SET_AUTH_CODE = `/api/auth/code/`;

export const SET_AUTH_PIN = `/api/profile/register/`;

export const AUTH_UNLOCK_PIN = `/api/auth/unlock/`;

export const AUTH_LOCK_PIN = `/api/auth/lock/`;

export const MNEMONIC = `/api/profile/phrase/`;

export const UUID_RESET_MNEMONIC = `/api/auth/phrase/`;

export const RECOVERY_PIN = `/api/auth/recovery/pin`;

export const RECOVERY_TG = `/api/auth/recovery/telegram`;

export const LOGOUT = `/api/profile/logout/`;

export async function getUserByToken(): Promise<UserModel> {
  const response = await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
  return response.data;
}

export const useSetAuthCode = () => {
  return useMutation("set-auth-code", async (code: string) => {
    const { data: response } = await axios.post(SET_AUTH_CODE, {
      code,
    });
    return response;
  });
};

export const useSetAuthPin = () => {
  return useMutation("set-auth-pin", async (pin: string) => {
    const { data: response } = await axios.post(SET_AUTH_PIN, {
      pin,
    });
    return response;
  });
};

export const useAuthUnlockPin = () => {
  return useMutation("unlock-pin", async (pin: string) => {
    const response = await axios.post(AUTH_UNLOCK_PIN, {
      pin,
    });
    return response.status;
  });
};

export const useAuthLockPin = () => {
  return useMutation("lock-pin", async () => {
    const { data: response } = await axios.post(AUTH_LOCK_PIN, {});
    return response;
  });
};

export const useAuthLogout = () => {
  return useMutation("logout", async (data: object) => {
    const { data: response } = await axios.post(LOGOUT, data);
    return response;
  });
};

export const useIsHaveMnemonic = () => {
  return useMutation("have-mnemonic", async () => {
    const { data: response } = await axios.get(MNEMONIC, {});
    return response;
  });
};

export const useSetPublicKey = () => {
  return useMutation("set-key", async (publicKeyHex: string) => {
    const { data: response } = await axios.post(MNEMONIC, {
      pub_key: publicKeyHex,
    });
    return response;
  });
};

export const useGetUuidResetMnemonic = () => {
  return useMutation("reset-uuid", async () => {
    const { data: response } = await axios.get(UUID_RESET_MNEMONIC, {});
    return response.uuid_hex;
  });
};

export const useRecoveryPin = () => {
  return useMutation("recovery-pin", async (sendData: RecoveryPinSheena) => {
    const { data: response } = await axios.post(RECOVERY_PIN, sendData);
    return response;
  });
};

export const useRecoveryTg = () => {
  return useMutation("recovery-tg", async (sendData: RecoveryTGSheena) => {
    const { data: response } = await axios.post(RECOVERY_TG, sendData);
    return response;
  });
};
