import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useIntl } from "react-intl";
import { CrypromusCrncInfo } from "../../core/_models";
import { CurrencyPageCrypto } from "./CurrencyPageCrypto";
import { useCreateWallet } from "../../core/_requests";
import { useAuth } from "../../../auth";
import { CurrencyPageQR } from "./CurrencyPageQR";

interface Props {
  selectedCurrency: CrypromusCrncInfo;
  setSelectedCurrency: React.Dispatch<React.SetStateAction<CrypromusCrncInfo>>;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectCurrencyModalBody: FC<Props> = ({
  selectedCurrency,
  setSelectedCurrency,
  setIsOpenModal,
}) => {
  const intl = useIntl();

  const { currentUser } = useAuth();

  const [page, setPage] = useState(1);

  const {
    mutate: createWallet,
    data: dataCreateWallet,
    isLoading: isCreateWalletLoading,
  } = useCreateWallet();

  useEffect(() => {
    if (dataCreateWallet && dataCreateWallet?.address) {
      setPage(2);
    }
  }, [dataCreateWallet]);

  const addNewWalletUser = () => {
    if (selectedCurrency) {
      createWallet({
        currency: selectedCurrency.currency,
        network: selectedCurrency.network,
        user_uuid: currentUser?.uuid as string,
      });
    }
  };

  const handlerFinishPage = () => {
    if (selectedCurrency) {
      setIsOpenModal(false);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between h-100">
        {page === 1 ? (
          <>
            <CurrencyPageCrypto
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
            />

            <div className="w-100 d-flex align-items-end justify-content-between">
              <div className="me-2"></div>
              <div>
                <button
                  type="button"
                  className="btn btn-lg btn-success d-flex flex-center"
                  onClick={addNewWalletUser}
                >
                  {!isCreateWalletLoading ? (
                    <>
                      {intl.formatMessage({ id: "BUTTON.SEND_REQUEST" })}
                      <i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  ) : (
                    <>
                      {intl.formatMessage({ id: "GLOBAL.PLEASE_WAIT" })}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {dataCreateWallet && (
              <CurrencyPageQR walletData={dataCreateWallet} />
            )}
            <div className="w-100 d-flex align-items-end justify-content-between">
              <div className="me-2"></div>
              <div>
                <button
                  type="button"
                  className="btn btn-lg btn-success d-flex flex-center"
                  onClick={handlerFinishPage}
                >
                  {intl.formatMessage({ id: "BUTTON.DONE" })}
                  <i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
