import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { MenuProvider } from "../_metronic/layout/components/menu/_MenuProvider";
import { AuthInit } from "./modules/auth";
import { UserProvider } from "./modules/accounts/core/UserContextProvider";

const App = () => {
  useEffect(() => {
    const handleTelegramLink = (event: any) => {
      const isPWA = window.matchMedia("(display-mode: standalone)").matches;
      if (isPWA) {
        event.preventDefault();
      }
    };

    window.addEventListener("load", handleTelegramLink);

    return () => {
      window.removeEventListener("load", handleTelegramLink);
    };
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <MenuProvider>
              <UserProvider>
                <Outlet />
                <MasterInit />
              </UserProvider>
            </MenuProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
