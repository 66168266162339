import { FC, useEffect } from "react";
import { SelectCurrencyModalHeader } from "./SelectCurrencyModalHeader";
import { SelectCurrencyModalBody } from "./SelectCurrencyModalBody";
import { CrypromusCrncInfo } from "../../core/_models";
import ModalWrapper from "../../../components/ModalWrapper";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCurrency: CrypromusCrncInfo;
  setSelectedCurrency: React.Dispatch<React.SetStateAction<CrypromusCrncInfo>>;
}

export const SelectCurrencyModal: FC<Props> = ({
  isOpenModal,
  setIsOpenModal,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <ModalWrapper isOpenModal={isOpenModal}>
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-600px ">
          {/* begin::Modal content */}
          <div className="modal-content h-650px h-md-750px">
            <SelectCurrencyModalHeader setIsOpenModal={setIsOpenModal} />
            {/* begin::Modal body */}
            <div className="modal-body mx-0 mx-xl-10  py-md-10">
              <SelectCurrencyModalBody
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                setIsOpenModal={setIsOpenModal}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </ModalWrapper>
    </>
  );
};
