import { FC, useState } from "react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QRCode from "react-qr-code";
import { KTIcon } from "../../../../_metronic/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { useGetWallet } from "../core/_requests";

interface Props {
  className: string;
}

export const TopUPQRCode: FC<Props> = React.memo(({ className }) => {
  const [copied, setCopied] = useState(false);

  const { data: walletAddress, isFetching: isLoadingWalletAddress } =
    useGetWallet();

  return (
    <div className={`card ${className} m-auto`} id="topUPQRCode">
      <div className="card-header border-0">
        <h3 className="mt-8 align-items-start flex-column text-center w-100">
          <span className="card-label fw-bold text-dark m-auto pt-2">
            <FormattedMessage
              id="PROFILE.TOP.UP.LK"
              values={
                {
                  b: (chunks: any) => (
                    <span className="fs-2 fw-bolder">{chunks}</span>
                  ),
                } as any
              }
            />
          </span>
        </h3>
      </div>

      <div className="card-body pt-0">
        {isLoadingWalletAddress ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <>
            <div
              className="p-5 bg-white rounded m-auto mb-4"
              style={{ width: "75%", maxWidth: "330px" }}
            >
              {walletAddress?.address && (
                <QRCode
                  size={256}
                  style={{
                    display: "block",
                    height: "auto",
                    width: "100%",
                    margin: "auto",
                  }}
                  value={walletAddress.address}
                  viewBox={`0 0 256 256`}
                />
              )}
            </div>
            <div className="fs-6 fs-md-4 fw-bold pt-3 d-flex justify-content-center align-items-center">
              {walletAddress?.address}
              <OverlayTrigger
                key="copy-to-clipboard"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-copy-to-clipboard">
                    <FormattedMessage
                      id={copied ? "COPY_TOOLTIP.COPIED" : "COPY_TOOLTIP.COPY"}
                    />
                  </Tooltip>
                }
              >
                <CopyToClipboard
                  text={walletAddress?.address}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 500);
                  }}
                >
                  <button
                    type="button"
                    className="ms-2 btn btn-icon btn-outline-secondary btn-sm"
                  >
                    <KTIcon iconName="copy" className="fs-2 text-muted me-1" />
                  </button>
                </CopyToClipboard>
              </OverlayTrigger>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
