import React, { useState } from "react";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { KTIcon } from "../../../../../_metronic/helpers";
import CopyToClipboard from "react-copy-to-clipboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import QRCode from "react-qr-code";
import { CurrencyUserData } from "../../core/_models";

interface Props {
  walletData: CurrencyUserData;
}

export const CurrencyPageQR: FC<Props> = ({ walletData }) => {
  const intl = useIntl();

  const [copied, setCopied] = useState(false);

  return (
    <div className={`w-100 mx-auto`}>
      <div className="">
        <h3 className="align-items-start flex-column text-center w-100">
          <span className="card-label fw-bold text-dark mx-auto">
            <FormattedMessage
              id="PROFILE.TOP.UP.LK"
              values={
                {
                  b: () => (
                    <span className="fs-2 fw-bolder">
                      {walletData.currency} {walletData.network.toUpperCase()}
                    </span>
                  ),
                } as any
              }
            />
          </span>
        </h3>
      </div>

      <div className="card-body pt-0">
        <div
          className="p-5 bg-white rounded m-auto mb-4"
          style={{ width: "60%", maxWidth: "330px" }}
        >
          {walletData.address && (
            <QRCode
              size={256}
              style={{
                display: "block",
                height: "auto",
                width: "100%",
                margin: "auto",
              }}
              value={walletData.address}
              viewBox={`0 0 256 256`}
            />
          )}
        </div>
        <div className="fs-7 fs-md-4 fw-bold pt-3 d-flex justify-content-center align-items-center">
          {walletData.address}
          <OverlayTrigger
            key="copy-to-clipboard"
            placement="top"
            overlay={
              <Tooltip id="tooltip-copy-to-clipboard">
                <FormattedMessage
                  id={copied ? "COPY_TOOLTIP.COPIED" : "COPY_TOOLTIP.COPY"}
                />
              </Tooltip>
            }
          >
            <CopyToClipboard
              text={walletData.address}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 500);
              }}
            >
              <button
                type="button"
                className="ms-2 btn btn-icon btn-outline-secondary btn-sm"
              >
                <KTIcon iconName="copy" className="fs-2 text-muted me-1" />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
        </div>
        <div className="mt-10">
          <h3>AML Policy</h3>
          <p>{intl.formatMessage({ id: "AML.POLICY" })}</p>
        </div>
      </div>
    </div>
  );
};
