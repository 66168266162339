import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { getUserByToken, useAuthLockPin, useAuthLogout } from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { UserModel, UserTelegramWidgetData } from "./_models";
import {
  setGlobalLockHandler,
  setGlobalSaveAuthHandler,
  setLockUserHandler,
} from "./AuthHelpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLanguage, useLang } from "../../../../_metronic/i18n/Metronici18n";

type AuthContextProps = {
  isAuth: boolean;
  setIsAuth: Dispatch<SetStateAction<boolean>>;
  saveAuth: () => void;
  logout: () => any;
  sessionLock: () => any;
  lockIfCondition: () => any;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  isAddPinCode: boolean;
  setIsAddPinCode: Dispatch<SetStateAction<boolean>>;
  isLockUser: boolean;
  userTgData: UserTelegramWidgetData | undefined;
  setUserTgData: Dispatch<SetStateAction<UserTelegramWidgetData | undefined>>;
  userCode: string | null;
  setUserCode: Dispatch<SetStateAction<string | null>>;
  previousUrl: string | null;
  setPreviousUrl: (url: string | null) => void;
  hasErrorsForgotPin: undefined | boolean;
  setHasErrorsForgotPin: Dispatch<SetStateAction<undefined | boolean>>;
  // savePreviousUrl: () => void;
};

const initAuthContextPropsState = {
  isAuth: false,
  setIsAuth: () => {},
  saveAuth: () => {},
  logout: () => {},
  sessionLock: () => {},
  lockIfCondition: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  isAddPinCode: false,
  setIsAddPinCode: () => {},
  isLockUser: false,
  userTgData: undefined,
  setUserTgData: () => {},
  userCode: null,
  setUserCode: () => {},
  previousUrl: null,
  setPreviousUrl: () => {},
  hasErrorsForgotPin: undefined,
  setHasErrorsForgotPin: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);

  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(
    undefined
  );

  const [hasErrorsForgotPin, setHasErrorsForgotPin] = useState<
    boolean | undefined
  >(undefined);

  const [userCode, setUserCode] = useState<string | null>(null);

  const [isAddPinCode, setIsAddPinCode] = useState<boolean>(false);
  const { mutate: setAuthLockPin } = useAuthLockPin();
  const { mutate: setAuthLogout } = useAuthLogout();

  const [isLockUser, setIsLockUser] = useState(false);

  const [userTgData, setUserTgData] = useState<
    UserTelegramWidgetData | undefined
  >(undefined);

  function saveAuth() {
    setIsAuth(true);
  }

  function resetAuth() {
    setIsAuth(true);
    setCurrentUser(undefined);
  }

  function sessionLock() {
    if (currentUser) {
      setAuthLockPin();
      setCurrentUser(undefined);
    }
  }

  function lockIfCondition() {
    setAuthLockPin();
    setCurrentUser(undefined);
  }

  function logout() {
    setAuthLogout(
      {},
      {
        onSuccess: () => {
          window.location.reload();

          window.location.href = "/";
        },
      }
    );
    setCurrentUser(undefined);
  }

  function lockUser() {
    setIsLockUser(true);
    setIsAuth(false);
  }

  useEffect(() => {
    setGlobalLockHandler(sessionLock);
    setGlobalSaveAuthHandler(resetAuth);
    setLockUserHandler(lockUser);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        saveAuth,

        logout,
        sessionLock,
        lockIfCondition,

        currentUser,
        setCurrentUser,
        isAddPinCode,
        setIsAddPinCode,
        isLockUser,

        userTgData,
        setUserTgData,
        userCode,
        setUserCode,

        previousUrl,
        setPreviousUrl,

        hasErrorsForgotPin,
        setHasErrorsForgotPin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const {
    currentUser,
    setCurrentUser,
    saveAuth,
    isAddPinCode,
    setIsAddPinCode,

    userCode,
    setUserCode,
    lockIfCondition,

    previousUrl,
    setPreviousUrl,
  } = useAuth();

  const locale = useLang();

  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const requestUser = async () => {
    try {
      if (!didRequest.current) {
        const data = await getUserByToken();
        if (data) {
          setCurrentUser(data);
          saveAuth();
        }
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setShowSplashScreen(false);
    }

    return () => (didRequest.current = true);
  };

  useEffect(() => {
    requestUser();
    if (currentUser) {
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentUser) {
      setPreviousUrl(window.location.pathname);
    } else if (currentUser && previousUrl) {
      navigate(previousUrl);
      setPreviousUrl(null);
    }
  }, [currentUser, previousUrl]);

  useEffect(() => {
    if (searchParams) {
      const code = searchParams.get("code");
      const language = searchParams.get("lang");

      if (language && language !== locale) {
        setLanguage(language);
      }

      if (code) {
        setUserCode(code);
      }

      if (code && !userCode) {
        lockIfCondition();
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isAddPinCode) {
      requestUser();

      setIsAddPinCode(false);
    }
    // eslint-disable-next-line
  }, [isAddPinCode]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, useAuth, AuthInit };
