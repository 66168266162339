import { useIntl } from "react-intl";
import { convertCentsToDollars } from "../../../utils";
import { useGetBalance } from "../core/_requests";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  stylesBlock?: string;
  stylesText?: string;
}

export const Balance: FC<Props> = ({ stylesBlock, stylesText }) => {
  const intl = useIntl();

  const navigate = useNavigate();

  const { data: balance, isFetching: isLoadingBalance } = useGetBalance({
    refetchInterval: 15000,
  });

  return (
    <div
      className={`${stylesBlock} border border-gray-300 border-dashed rounded cursor-pointer`}
      onClick={() => {
        navigate("/wallet");
      }}
    >
      <div className="d-flex align-items-center">
        {/* Icon removed for brevity */}
        <div className="fs-md-3 fs-4 fw-bolder">
          {isLoadingBalance ? (
            <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
          ) : (
            <span className="fs-md-3 fs-5 fw-bolder">
              {convertCentsToDollars(balance?.total - balance?.hold)} USDT
            </span>
          )}
        </div>
      </div>

      <div className={`${stylesText} fw-bold fs-6 text-gray-400`}>
        {intl.formatMessage({ id: "BALANCE.TITLE" })}
      </div>
    </div>
  );
};
