import React, { useState } from "react";
import { useGetFaq } from "./_requests";
import { useIntl } from "react-intl";
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { CategoryFaq } from "../../../api/core-contract";

interface Props {}

const TG_SUPPORT = process.env.REACT_APP_TELEGRAM_SUPPORT as string;

export const Faq: React.FC<Props> = React.memo(() => {
  const intl = useIntl();

  const locale = useLang();

  const { data, isLoading } = useGetFaq();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  const handleCategoryClick = (categoryId: number) => {
    setSelectedCategoryId(
      categoryId === selectedCategoryId ? null : categoryId
    );
  };

  function filterDataByLanguage(data: CategoryFaq[], languageCode: string) {
    const filteredData = data?.filter(
      (item) => item.language_code === languageCode
    );

    // Фильтруем вложенные вопросы
    // filteredData.forEach((item) => {
    //   item.questions = item.questions.filter(
    //     (q) => q.language_code === languageCode
    //   );
    // });

    return filteredData;
  }

  return (
    <div id="kt_app_content_container">
      <div className="card">
        <div className="card-body p-lg-15">
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-column flex-lg-row-auto w-100 w-lg-275px mb-10 me-lg-20">
              <div className="mb-15 d-lg-none">
                <h4 className="fs-md-2x fs-4 text-gray-800 w-bolder mb-6">
                  {intl.formatMessage({ id: "FAQS.TITLE" })}
                </h4>
                <p className="fw-semibold fs-sm-4 fs-7 text-gray-600 mb-10">
                  {intl.formatMessage({ id: "FAQS.DESCRIPTION" })}
                </p>

                <div className="separator separator-dashed mb-6"></div>

                <h6 className="fs-sm-4 fs-6 text-gray-800 w-bolder mb-6">
                  {intl.formatMessage({ id: "FAQS.SUPPORT" })}{" "}
                  <a href={`https://t.me/helpgrossbot`} target="_blank">
                    @helpgrossbot
                  </a>
                </h6>

                <div className="separator separator-dashed mb-2"></div>
              </div>
              <div className="mb-10">
                <h4 className="text-dark mb-7 fs-md-4 fs-5">
                  {intl.formatMessage({ id: "CATEGORIES.TITLE" })}
                </h4>
                <div className="menu menu-rounded menu-column menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-semibold">
                  {data &&
                    filterDataByLanguage(data.categories, locale)?.map(
                      (category) => (
                        <div className="menu-item mb-1" key={category.id}>
                          <a
                            href="#"
                            className={`menu-link py-3 ${
                              selectedCategoryId === category.id ? "active" : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCategoryClick(category.id);
                            }}
                          >
                            {category.title}
                          </a>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <div className="flex-lg-row-fluid">
              <div className="mb-13">
                <div className="mb-15 d-none d-lg-block">
                  <h4 className="fs-md-4 fs-5 text-gray-800 w-bolder mb-6">
                    {intl.formatMessage({ id: "FAQS.TITLE" })}
                  </h4>
                  <p className="fw-semibold fs-md-4 fs-5 text-gray-600 mb-10">
                    {intl.formatMessage({ id: "FAQS.DESCRIPTION" })}
                  </p>

                  <div className="separator separator-dashed mb-6"></div>

                  <h6 className="fs-md-4 fs-5 text-gray-800 w-bolder mb-6">
                    {intl.formatMessage({ id: "FAQS.SUPPORT" })}{" "}
                    <a href={`https://t.me/helpgrossbot`} target="_blank">
                      @helpgrossbot
                    </a>
                  </h6>

                  <div
                    className={`separator separator-dashed mb-2  ${TG_SUPPORT} `}
                  ></div>
                </div>
                <div className="row mb-12">
                  {data &&
                    filterDataByLanguage(data.categories, locale)
                      ?.filter(
                        (category) =>
                          selectedCategoryId === null ||
                          category.id === selectedCategoryId
                      )
                      ?.map((category) => (
                        <div
                          className="col-md-6 pe-md-10 mb-15"
                          key={category.id}
                        >
                          <h2 className="fs-md-4 fs-5 text-gray-800 fw-bold mb-4">
                            {category.title}
                          </h2>
                          {category.questions.length > 0 ? (
                            category?.questions?.map((question) => (
                              <div className="m-0" key={question.id}>
                                <div
                                  className="d-flex align-items-center collapsible collapsed py-3 toggle mb-0"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#kt_job_${category.id}_${question.id}`}
                                >
                                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                    <i className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                    <i className="ki-duotone ki-plus-square toggle-off fs-1">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                    </i>
                                  </div>
                                  <h4
                                    className="fs-md-5 fs-6 text-gray-700 fw-semibold cursor-pointer mb-0"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {question.question}
                                  </h4>
                                </div>
                                <div
                                  id={`kt_job_${category.id}_${question.id}`}
                                  className="collapse fs-6 ms-1"
                                >
                                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                                    <span
                                      style={{ whiteSpace: "pre-line" }}
                                      dangerouslySetInnerHTML={{
                                        __html: question.answer,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                                <div className="separator separator-dashed"></div>
                              </div>
                            ))
                          ) : (
                            <p>
                              {intl.formatMessage({
                                id: "FAQS.NO_QUESTIONS",
                              })}
                            </p>
                          )}
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
