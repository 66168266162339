import { useIntl } from "react-intl";
import { SelectCurrencyModal } from "./select-currency-modal/SelectCurrencyModal";
import { useState } from "react";
import { CrypromusCrncInfo } from "../core/_models";
import { WithdrawModal } from "./withdraw-modal/WithdrawModal";
import { QrWalletModal } from "./qr-wallet-modal/QrWalletModal";

const startCurrency = {
  currency: "DOGE",
  network: "DOGE",
  is_available: true,
};

export const Withdraw = () => {
  const intl = useIntl();

  const [isOpenQrModal, setIsOpenQrModal] = useState(false);
  const [isWithdrawModal, setIsWithdrawModal] = useState(false);
  const [isOpenSelectCurrencyModal, setIsOpenSelectCurrencyModal] =
    useState(false);

  const [selectedCurrency, setSelectedCurrency] =
    useState<CrypromusCrncInfo>(startCurrency);

  return (
    <>
      <div className="card-px text-center pt-10 pb-10">
        <h2 className="fs-1 fw-bold mb-8">
          {intl.formatMessage({ id: "ASSETS.TITLE" })}
        </h2>

        <div className="text-gray-700 row flex-center">
          <div className="col-4 col-md-2">
            <button
              className={`btn text-hover-primary`}
              onClick={() => setIsOpenQrModal(true)}
            >
              <div className="border border-gray-300 border-2 rounded px-4 pt-5 pb-2">
                <i className="ki-duotone ki-exit-down fs-2x">
                  <span className="path1 text-warning"></span>
                  <span className="path2"></span>
                </i>
              </div>
              <div className="fs-6">
                {intl.formatMessage({ id: "ASSETS.DEPOSIT" })}
              </div>
            </button>
          </div>

          <div className="col-4 col-md-2">
            <button
              className={`btn text-hover-primary`}
              onClick={() => setIsWithdrawModal(true)}
            >
              <div className="border border-gray-300 border-2 rounded px-4 pt-5 pb-2">
                <i className="ki-duotone ki-exit-up fs-2x">
                  <span className="path1 text-warning"></span>
                  <span className="path2"></span>
                </i>
              </div>
              <div className="fs-6">
                {intl.formatMessage({ id: "ASSETS.WITHDRAW" })}
              </div>
            </button>
          </div>

          <div className="col-4 col-md-2">
            <button
              className={`btn text-hover-primary`}
              onClick={() => setIsOpenSelectCurrencyModal(true)}
            >
              <div className="border border-gray-300 border-2 rounded px-4 pt-5 pb-2">
                <i className="ki-duotone ki-two-credit-cart fs-2x">
                  <span className="path1"></span>
                  <span className="path2 text-warning"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
              </div>
              <div className="fs-6">
                {intl.formatMessage({ id: "ASSETS.BUY" })}
              </div>
            </button>
          </div>
        </div>
      </div>

      {isOpenSelectCurrencyModal && (
        <SelectCurrencyModal
          isOpenModal={isOpenSelectCurrencyModal}
          setIsOpenModal={setIsOpenSelectCurrencyModal}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />
      )}

      {isOpenQrModal && (
        <QrWalletModal
          isOpenModal={isOpenQrModal}
          setIsOpenModal={setIsOpenQrModal}
        />
      )}

      {isWithdrawModal && (
        <WithdrawModal
          isOpenModal={isWithdrawModal}
          setIsOpenModal={setIsWithdrawModal}
        />
      )}
    </>
  );
};
