import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import {
  ready,
  crypto_sign_seed_keypair,
  to_hex,
  crypto_sign_detached,
} from "libsodium-wrappers";
import { mnemonicToSeed } from "bip39";
import { useGetUuidResetMnemonic, useRecoveryPin } from "../core/_requests";
import { ShowError } from "../../errors/components/ShowError";
import { useAuth } from "../core/Auth";

const initialValues = {
  mnemonic: "",
  password: "",
  changepassword: "",
  showPassword: false,
  showConfirmPassword: false,
};

const digitOnlyRegex = /^[0-9]{6}$/;

export function ForgotPassword() {
  const intl = useIntl();

  const { hasErrorsForgotPin, setHasErrorsForgotPin } = useAuth();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<any | null>(null);

  const forgotPasswordSchema = Yup.object().shape({
    mnemonic: Yup.string()
      .trim()
      .matches(
        /^(?:\b\w+\b[\s\r\n]*){12,12}$/,
        "The mnemonic phrase must consist of exactly 12 words."
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    password: Yup.string()
      .trim()
      .length(
        6,
        intl.formatMessage({ id: "AUTH.VALIDATION.LENGTH" }, { length: 6 })
      )
      .matches(
        digitOnlyRegex,
        intl.formatMessage({ id: "AUTH.VALIDATION.DIGITS_ONLY" })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),

    changepassword: Yup.string()
      .trim()
      .length(
        6,
        intl.formatMessage({ id: "AUTH.VALIDATION.LENGTH" }, { length: 6 })
      )
      .matches(
        digitOnlyRegex,
        intl.formatMessage({ id: "AUTH.VALIDATION.DIGITS_ONLY" })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .oneOf(
        [Yup.ref("password")],
        intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_MISMATCH" })
      ),
  });

  const {
    mutate: getResetUuid,
    isLoading: isGetUuidLoading,
    data: resetUuid,
    error: getUuidError,
  } = useGetUuidResetMnemonic();

  const {
    mutate: recoveryPin,
    isLoading: isRecoveryPinLoading,
    data: recoveryPinCode,
    error: recoveryPinError,
  } = useRecoveryPin();

  const navigate = useNavigate();

  useEffect(() => {
    if (getUuidError || recoveryPinError) {
      setHasErrorsForgotPin(true);
      intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_MISMATCH" });
    }
  }, [getUuidError, recoveryPinError]);

  useEffect(() => {
    if (recoveryPinCode) {
      setHasErrorsForgotPin(false);
      navigate("/auth");
    }
  }, [recoveryPinCode]);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrorsForgotPin(undefined);

      getResetUuid();
    },
  });

  async function generateSendMessage(uuid: string) {
    try {
      await ready;

      const seedBuffer = await mnemonicToSeed(formik?.values.mnemonic.trim());

      const seed = seedBuffer.slice(0, 32);

      const keyPair = crypto_sign_seed_keypair(seed);

      const messageBytes = new TextEncoder().encode(uuid); // Преобразование строки в Uint8Array
      const signedMessage = crypto_sign_detached(
        messageBytes,
        keyPair.privateKey
      );

      // Перевод подписанного сообщения в hex-формат
      const publicKeyHex = to_hex(keyPair.publicKey);
      const signedMessageHex = to_hex(signedMessage);

      recoveryPin({
        uuid_hex: resetUuid,
        public_key_hex: publicKeyHex,
        signature: signedMessageHex,
        pin: formik?.values.password.trim(),
      });

      return true;
    } catch (error) {
      console.error("Error in generating keys and signing the message:", error);
      // В случае ошибок возвращаем false
      return false;
    }
  }

  useEffect(() => {
    if (resetUuid) {
      generateSendMessage(resetUuid);
    }
  }, [resetUuid]);

  return (
    <form
      className="form w-100 row flex-center  fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10 col-12">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })}?
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.ENTER_SEED_AND_PIN" })}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrorsForgotPin === true && (
        <div className="mb-10 p-8  alert alert-danger col-12 col-lg-9">
          <div className="alert-text font-weight-bold text-center">
            {intl.formatMessage({ id: "AUTH.RESET.EMAIL_ERROR" })}
          </div>
        </div>
      )}

      {/* end::Title */}

      {/* begin::Form group */}
      <div className="fv-row mb-8 col-lg-9">
        <label className="form-label fw-bolder text-gray-900 fs-6">
          {intl.formatMessage({ id: "AUTH.ENTER_PHRASE" })}
        </label>
        <input
          type="mnemonic"
          placeholder={intl.formatMessage({ id: "SEED.PHRASE" })}
          autoComplete="off"
          {...formik.getFieldProps("mnemonic")}
          className={clsx(
            "form-control bg-transparent w-100",
            { "is-invalid": formik.touched.mnemonic && formik.errors.mnemonic },
            {
              "is-valid": formik.touched.mnemonic && !formik.errors.mnemonic,
            }
          )}
        />
        {formik.touched.mnemonic && formik.errors.mnemonic && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block fs-8 fs-md-6">
              <span role="alert">{formik.errors.mnemonic}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="fv-row mb-8 col-lg-9" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.NEW_PASSWORD_TITLE" })}
          </label>
          <div className="position-relative mb-3">
            <input
              type={formik.values.showPassword ? "text" : "password"}
              placeholder={intl.formatMessage({
                id: "AUTH.INPUT.NEW_PASSWORD_PLACEHOLDER",
              })}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
              onClick={() =>
                formik.setFieldValue(
                  "showPassword",
                  !formik.values.showPassword
                )
              }
            >
              <i
                className={`bi fs-2 ${
                  formik.values.showPassword
                    ? "bi-eye-slash-fill"
                    : "bi-eye-fill"
                }`}
              ></i>
            </span>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block fs-8 fs-md-6">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}

          {/* end::Meter */}
        </div>
        <div className="text-muted">
          {intl.formatMessage({ id: "AUTH.USE_6_DIGITS" })}
        </div>{" "}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-12 col-lg-9">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD_TITLE" })}
        </label>
        <div className="position-relative">
          <input
            type={formik.values.showConfirmPassword ? "text" : "password"}
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.CONFIRM_PASSWORD_PLACEHOLDER",
            })}
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid":
                  formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                "is-valid":
                  formik.touched.changepassword &&
                  !formik.errors.changepassword,
              }
            )}
          />
          <span
            className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
            onClick={() =>
              formik.setFieldValue(
                "showConfirmPassword",
                !formik.values.showConfirmPassword
              )
            }
          >
            <i
              className={`bi fs-2 ${
                formik.values.showConfirmPassword
                  ? "bi-eye-slash-fill"
                  : "bi-eye-fill"
              }`}
            ></i>
          </span>
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block fs-8 fs-md-6">
                <span role="alert">{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-between pb-lg-0 col-lg-9">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          disabled={loading || isGetUuidLoading || isRecoveryPinLoading}
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "AUTH.RESET.EMAIL_CONFIRM" })}
          </span>
          {(loading || isGetUuidLoading || isRecoveryPinLoading) && (
            <span className="indicator-progress">
              {intl.formatMessage({ id: "GLOBAL.WAIT" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link reloadDocument to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light px-15"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
        </Link>{" "}
      </div>

      <div className="mt-9 mb-12 mw-375px mx-auto text-center">
        {error && ShowError(error)}
      </div>
      {/* end::Form group */}
    </form>
  );
}
